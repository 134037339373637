import React, { useState } from 'react';
import {
  Typography, TextField, Grid, Paper, Radio, RadioGroup, FormControl, FormControlLabel,
  FormLabel, Box
} from '@mui/material';
import { getMatricMarks, getFAFScMarks } from './helpers';

const ExamSet3Component = () => {
  const [matricMarks, setMatricMarks] = useState({ obtained: '', total: '' });
  const [fscMarks, setFscMarks] = useState({ obtained: '', total: '' });
  const [bsbaMarks, setBsBaMarks] = useState({ obtained: '', total: '' });
  const [systemType, setSystemType] = useState('annual');
  const [errors, setErrors] = useState({ matric: false, fsc: false, bsba: false });

  const validateTotalMarks = (total, system = 'annual') => {
    return system === 'semester' ? (total >= 0 && total <= 4) : (total >= 700 && total <= 1500);
  };

  const calculatePercentage = (obtained, total, system = 'annual') => {
    if (!validateTotalMarks(total, system)) {
      return system === 'semester' ? 'Invalid GPA' : 'Invalid total marks';
    }
    return system === 'semester' ? `${obtained}` : `${(obtained / total * 100).toFixed(2)}%`;
  };

  const handleTotalChange = (e, examType) => {
    const total = e.target.value;
    const system = (examType === 'bsba' && systemType === 'semester') ? 'semester' : 'annual';
    const isValid = validateTotalMarks(total, system);

    setErrors({ ...errors, [examType]: !isValid });

    if (examType === 'matric') {
      setMatricMarks({ ...matricMarks, total });
    } else if (examType === 'fsc') {
      setFscMarks({ ...fscMarks, total });
    } else {
      setBsBaMarks({ ...bsbaMarks, total });
    }
  };

  const handleObtainedChange = (e, examType) => {
    const obtained = e.target.value;
    if (examType === 'matric') {
      setMatricMarks({ ...matricMarks, obtained });
    } else if (examType === 'fsc') {
      setFscMarks({ ...fscMarks, obtained });
    } else {
      setBsBaMarks({ ...bsbaMarks, obtained });
    }
  };

  return (
    <Paper elevation={3} sx={{ my: 4, p: 4, borderRadius: 2 }}>
      <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mb: 3 }}>
        Enter Academic Marks for Matric, FA/FSc, and BS/BA
      </Typography>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {/* Sections for each group of exams */}
        <Box sx={{ width: '100%', pb: 2 }}>
          <Typography variant="h6" align="center" color="primary" gutterBottom>
            Secondary School Exams (Matric & FA/FSc)
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {renderExamFields('matric', matricMarks, 'Matric')}
            {renderExamFields('fsc', fscMarks, 'FA/FSc')}
          </Grid>
        </Box>
        <Box sx={{ width: '100%', pt: 2 }}>
          <Typography variant="h6" align="center" color="secondary" gutterBottom>
            Higher Education Exams (BS/BA)
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {renderBSBAFields()}
          </Grid>
        </Box>
      </Grid>
      <Box mt={4} textAlign="center">
        <Typography variant="h6" gutterBottom>
          Total Academic Marks:
        </Typography>
        <Typography variant="h4" color="secondary">
          {getTotalMarks()}
        </Typography>
      </Box>
    </Paper>
  );

  function renderExamFields(examType, examMarks, label) {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <TextField
          error={errors[examType]}
          helperText={errors[examType] ? "Total marks must be between 700 and 1500." : " "}
          label={`${label} Total Marks`}
          type="number"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          value={examMarks.total}
          onChange={(e) => handleTotalChange(e, examType)}
          sx={{ mb: 2 }}
        />
        <TextField
          label={`${label} Obtained Marks`}
          type="number"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          value={examMarks.obtained}
          onChange={(e) => handleObtainedChange(e, examType)}
          sx={{ mb: 2 }}
        />
        <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
          {label} Percentage: <strong>{calculatePercentage(examMarks.obtained, examMarks.total)}</strong>
        </Typography>
      </Grid>
    );
  }

  function renderBSBAFields() {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <FormControl component="fieldset" sx={{ width: '100%', mb: 2 }}>
          <FormLabel component="legend">BS/BA System Type</FormLabel>
          <RadioGroup
            row
            aria-label="system-type"
            name="system-type"
            value={systemType}
            onChange={(e) => setSystemType(e.target.value)}
          >
            <FormControlLabel value="annual" control={<Radio />} label="Annual" />
            <FormControlLabel value="semester" control={<Radio />} label="Semester" />
          </RadioGroup>
        </FormControl>
        <TextField
          error={errors.bsba}
          helperText={errors.bsba ? (systemType === 'semester' ? 'GPA must be between 0 and 4.' : 'Total marks must be between 700 and 1500.') : " "}
          label={systemType === 'semester' ? "GPA" : "BS/BA Total Marks"}
          type="number"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          value={bsbaMarks.total}
          onChange={(e) => handleTotalChange(e, 'bsba')}
          sx={{ mb: 2 }}
        />
        {systemType === 'annual' && (
          <TextField
            label="BS/BA Obtained Marks"
            type="number"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            value={bsbaMarks.obtained}
            onChange={(e) => handleObtainedChange(e, 'bsba')}
            sx={{ mb: 2 }}
          />
        )}
        <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
          {systemType === 'semester' ? "BS/BA GPA" : "BS/BA Percentage"}: <strong>{calculatePercentage(bsbaMarks.obtained, bsbaMarks.total, systemType)}</strong>
        </Typography>
      </Grid>
    );
  }

  function getTotalMarks() {
    const matricPercentage = parseInt(calculatePercentage(matricMarks.obtained, matricMarks.total));
    const fscPercentage = parseInt(calculatePercentage(fscMarks.obtained, fscMarks.total));
    const totalMatricMarks = getMatricMarks(matricPercentage) || 0;
    const totalFscMarks = getFAFScMarks(fscPercentage) || 0;
    const sum = totalMatricMarks + totalFscMarks;
    return `${sum}/40`;
  }
};

export default ExamSet3Component;
