import React, { useState } from 'react';
import { Typography, TextField, Grid, Paper, Box, Switch, FormControlLabel } from '@mui/material';
import {getMatricMarks, getFAFScMarks} from './helpers';

const ExamSet4Component = () => {
  const [matricMarks, setMatricMarks] = useState({ obtained: '', total: '' });
  const [fscMarks, setFscMarks] = useState({ obtained: '', total: '' });
  const [baMarks, setBaMarks] = useState({ obtained: '', total: '' });
  const [maMarks, setMaMarks] = useState({ obtained: '', total: '' });
  const [errors, setErrors] = useState({ matric: false, fsc: false, ba: false, ma: false });
  const [examSystem, setExamSystem] = useState('annual'); // 'semester' or 'annual'

  const validateTotalMarks = (total, examType) => {
    if (examType === 'semester') {
      return total === 4; // GPA is always 4 in the semester system
    }
    return total >= 700 && total <= 1500; // Annual system range
  };

  const calculatePercentage = (obtained, total) => {
    if (!validateTotalMarks(total, examSystem)) {
      return 'Invalid total marks';
    }
    return ((obtained / total) * 100).toFixed(2) + '%';
  };

  const handleTotalChange = (e, examType) => {
    const total = examSystem === 'semester' ? 4 : e.target.value;
    const isValid = validateTotalMarks(total, examSystem);

    setErrors({ ...errors, [examType]: !isValid });

    if (examType === 'matric') {
      setMatricMarks({ ...matricMarks, total });
    } else if (examType === 'fsc') {
      setFscMarks({ ...fscMarks, total });
    } else if (examType === 'ba') {
      setBaMarks({ ...baMarks, total });
    } else if (examType === 'ma') {
      setMaMarks({ ...maMarks, total });
    }
  };

  const getTotalMarks = () => {
    const percentages = ['matric', 'fsc', 'ba', 'ma'].map(exam =>
      parseInt(calculatePercentage(
        exam === 'matric' ? matricMarks.obtained : exam === 'fsc' ? fscMarks.obtained : exam === 'ba' ? baMarks.obtained : maMarks.obtained,
        exam === 'matric' ? matricMarks.total : exam === 'fsc' ? fscMarks.total : exam === 'ba' ? baMarks.total : maMarks.total
      ))
    );
    const totalMarks = [getMatricMarks, getFAFScMarks, getMatricMarks, getFAFScMarks].map((func, index) => func(percentages[index]) || 0);
    const sum = totalMarks.reduce((a, b) => a + b, 0);
    return `${sum}/40`;
  };

  return (
    <Paper elevation={3} sx={{ my: 4, p: 4, borderRadius: 2 }}>
      <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mb: 3 }}>
        Enter Academic Marks for 4 Exams
      </Typography>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={examSystem === 'semester'} onChange={() => setExamSystem(examSystem === 'annual' ? 'semester' : 'annual')} />}
            label="Semester System (GPA) / Annual System"
            sx={{ mb: 2 }}
          />
        </Grid>
        {/* Separate sections for each group of exams */}
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', pb: 2 }}>
          <Typography variant="h6" align="center" color="primary" gutterBottom>
            Secondary School Exams (Matric & FA/FSc)
          </Typography>
          {['matric', 'fsc'].map((exam) => (
            <Grid item xs={12} sm={6} md={3} key={exam}>
              {renderExamFields(exam)}
            </Grid>
          ))}
        </Box>
        <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider', pt: 2 }}>
          <Typography variant="h6" align="center" color="secondary" gutterBottom>
            Higher Education Exams (BA & MA)
          </Typography>
          {['ba', 'ma'].map((exam) => (
            <Grid item xs={12} sm={6} md={3} key={exam}>
              {renderExamFields(exam)}
            </Grid>
          ))}
        </Box>
      </Grid>
       <Box mt={4} textAlign="center">
        <Typography variant="h6" gutterBottom>
          Total Academic Marks:
        </Typography>
        <Typography variant="h4" color="secondary">
          {getTotalMarks()}
        </Typography>
      </Box>
    </Paper>
  );

  function renderExamFields(exam) {
    return (
      <>
        <TextField
          error={errors[exam]}
          helperText={errors[exam] ? "Total marks must be 4 (GPA) or between 700 and 1500 (Annual)." : " "}
          label={`${exam.toUpperCase()} Total Marks`}
          type="number"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          value={exam === 'matric' ? matricMarks.total : exam === 'fsc' ? fscMarks.total : exam === 'ba' ? baMarks.total : maMarks.total}
          onChange={(e) => handleTotalChange(e, exam)}
          sx={{ mb: 2 }}
        />
        <TextField
          label={`${exam.toUpperCase()} Obtained Marks`}
          type="number"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          fullWidth
          value={exam === 'matric' ? matricMarks.obtained : exam === 'fsc' ? fscMarks.obtained : exam === 'ba' ? baMarks.obtained : maMarks.obtained}
          onChange={(e) => {
            if (exam === 'matric') {
              setMatricMarks({ ...matricMarks, obtained: e.target.value });
            } else if (exam === 'fsc') {
              setFscMarks({ ...fscMarks, obtained: e.target.value });
            } else if (exam === 'ba') {
              setBaMarks({ ...baMarks, obtained: e.target.value });
            } else if (exam === 'ma') {
              setMaMarks({ ...maMarks, obtained: e.target.value });
            }
          }}
          sx={{ mb: 2 }}
        />
        <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
          {`${exam.toUpperCase()} Percentage: `}
          <strong>{calculatePercentage(
            exam === 'matric' ? matricMarks.obtained : exam === 'fsc' ? fscMarks.obtained : exam === 'ba' ? baMarks.obtained : maMarks.obtained,
            exam === 'matric' ? matricMarks.total : exam === 'fsc' ? fscMarks.total : exam === 'ba' ? baMarks.total : maMarks.total
          )}</strong>
        </Typography>
      </>
    );
  }
};

export default ExamSet4Component;
