export const  getMatricMarks= (percentage) =>{
  const matricRanges = [
    { min: 80, max: 100, marks: 15 },
    { min: 75, max: 79, marks: 14 },
    { min: 70, max: 74, marks: 13 },
    { min: 65, max: 69, marks: 12 },
    { min: 60, max: 64, marks: 11 },
    { min: 55, max: 59, marks: 10 },
    { min: 50, max: 54, marks: 9 },
    { min: 45, max: 49, marks: 8 },
    { min: 40, max: 44, marks: 7 },
    { min: 0, max: 39, marks: 7 },
  ];

  for (const range of matricRanges) {
    if (percentage >= range.min && percentage <= range.max) {
      return range.marks;
    }
  }

  return null;
}

export const  getFAFScMarks = (percentage) => {
  const faFscRanges = [
    { min: 80, max: 100, marks: 25 },
    { min: 75, max: 79, marks: 24 },
    { min: 70, max: 74, marks: 23 },
    { min: 65, max: 69, marks: 22 },
    { min: 60, max: 64, marks: 22 },
    { min: 55, max: 59, marks: 20 },
    { min: 50, max: 54, marks: 19 },
    { min: 45, max: 49, marks: 17 },
    { min: 40, max: 44, marks: 17 },
    { min: 0, max: 39, marks: 17 },
  ];

  for (const range of faFscRanges) {
    if (percentage >= range.min && percentage <= range.max) {
      return range.marks;
    }
  }

  return null;
}

