import React, { useState } from 'react';
import { Typography, TextField, Grid, Paper, Box } from '@mui/material';
import {getMatricMarks,getFAFScMarks} from './helpers'
const ExamSet2Component = () => {
  const [matricMarks, setMatricMarks] = useState({ obtained: '', total: '' });
  const [fscMarks, setFscMarks] = useState({ obtained: '', total: '' });
  const [errors, setErrors] = useState({ matric: false, fsc: false });

  const validateTotalMarks = (total) => total >= 700 && total <= 1500;

  const calculatePercentage = (obtained, total) => {
    if (!validateTotalMarks(total)) {
      return 'No Marks Provided';
    }
    return ((obtained / total) * 100).toFixed(2) + '%';
  };

  const handleTotalChange = (e, examType) => {
    const total = e.target.value;
    const isValid = validateTotalMarks(total);

    setErrors({ ...errors, [examType]: !isValid });

    const newMarks = examType === 'matric' ? { ...matricMarks, total } : { ...fscMarks, total };
    examType === 'matric' ? setMatricMarks(newMarks) : setFscMarks(newMarks);
  };


    const getTotalMarks = () => {
    const matricPercentage = parseInt(calculatePercentage(matricMarks.obtained, matricMarks.total));
    const fscPercentage = parseInt(calculatePercentage(fscMarks.obtained, fscMarks.total));
    const totalMatricMarks = getMatricMarks(matricPercentage) || 0;
    const totalFscMarks = getFAFScMarks(fscPercentage) || 0;
    const sum = totalMatricMarks + totalFscMarks;
    return `${sum}/40`;
  };

  return (
    <Paper elevation={3} sx={{ my: 4, p: 4, borderRadius: 2 }}>
      <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', mb: 3 }}>
        Enter Academic Marks for 2 Exams
      </Typography>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {/* Matriculation Marks */}
        <Grid item xs={12} sm={6} md={4} >
          <TextField
            error={errors.matric}
            helperText={errors.matric ? "Total marks must be between 700 and 1500." : " "}
            label="Matric Total Marks"
            type="number"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            value={matricMarks.total}
            onChange={(e) => handleTotalChange(e, 'matric')}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Matric Obtained Marks"
            type="number"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            value={matricMarks.obtained}
            onChange={(e) => setMatricMarks({ ...matricMarks, obtained: e.target.value })}
            sx={{ mb: 2 }}
          />
          <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
            Matric Percentage: <strong>{calculatePercentage(matricMarks.obtained, matricMarks.total)}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            error={errors.fsc}
            helperText={errors.fsc ? "Total marks must be between 700 and 1500." : " "}
            label="FA/FSc Total Marks"
            type="number"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            value={fscMarks.total}
            onChange={(e) => handleTotalChange(e, 'fsc')}
            sx={{ mb: 2 }}
          />
          <TextField
            label="FA/FSc Obtained Marks"
            type="number"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            value={fscMarks.obtained}
            onChange={(e) => setFscMarks({ ...fscMarks, obtained: e.target.value })}
            sx={{ mb: 2 }}
          />
          <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
            FA/FSc Percentage: <strong>{calculatePercentage(fscMarks.obtained, fscMarks.total)}</strong>
          </Typography>
        </Grid>
      </Grid>
       <Box mt={4} textAlign="center">
        <Typography variant="h6" gutterBottom>
          Total Acedemic Marks:
        </Typography>
        <Typography variant="h4" color="secondary">
          {getTotalMarks()}
        </Typography>
      </Box>
    </Paper>
  );
}

export default ExamSet2Component;
