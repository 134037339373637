// import React, { useState } from 'react';
// import { Grid, Typography, Button } from '@mui/material';
// import ExamSet3Component from './components/ExamSet3Component';
// import ExamSet2Component from './components/ExamSet2Component';
// import ExamSet4Component from './components/ExamSet4Component';


// const componentsMap = {
//   'Select 2 Exam Set': <ExamSet2Component />,
//   'Select 3 Exam Set': <ExamSet3Component />,
//   'Select 4 Exam Set': <ExamSet4Component />,
// };

// const App = () => {
//   const [examsSet, setExamsSet] = useState('');

//   const handleButtonClick = (setNumber) => {
//     setExamsSet(`Select ${setNumber} Exam Set`);
//   };

//   const handleResetClick = () => {
//     setExamsSet('');
//   };

//   return (
//     <Grid container direction="column" alignItems="center" sx={{ padding: '32px' }}>
//       <Typography variant='h2' align='center' sx={{ marginBottom: '20px' }}>
//         PPSC Merit Calculator
//       </Typography>
//       <Typography variant='h4' align='center' sx={{ marginBottom: '20px' }}>
//         Please Select Your Exams Set
//       </Typography>
//       <Grid container spacing={2} xs={12} justifyContent='space-between' mt='20px'>
//         <Grid item container xs={3} justifyContent="center">
//           <Typography variant="body1" align="center" component="ol">
//             <li sx={{ marginBottom: '8px' }}>Matric</li>
//             <li sx={{ marginBottom: '8px' }}>FA/F.Sc</li>
//           </Typography>
//           <Grid item xs={12} container justifyContent='center'>
//             <Button variant="contained" color="primary" onClick={() => handleButtonClick(2)}>
//               Select 2 Exam Set
//             </Button>
//           </Grid>
//         </Grid>
//         <Grid item container justifyContent="center" xs={3}>
//           <Typography variant="body1" align="center" component="ol">
//             <li sx={{ marginBottom: '8px' }}>Matric</li>
//             <li sx={{ marginBottom: '8px' }}>FA/F.Sc</li>
//             <li sx={{ marginBottom: '8px' }}>BS(4 Years)</li>
//           </Typography>
//           <Grid item xs={12} container justifyContent='center'>
//             <Button variant="contained" color="primary" onClick={() => handleButtonClick(3)}>
//               Select 3 Exam Set
//             </Button>
//           </Grid>
//         </Grid>
//         <Grid item container justifyContent="center" xs={3}>
//           <Typography variant="body1" align="center" component="ol">
//             <li sx={{ marginBottom: '8px' }}>Matric</li>
//             <li sx={{ marginBottom: '8px' }}>FA/F.Sc</li>
//             <li sx={{ marginBottom: '8px' }}>BA/B.Sc</li>
//             <li sx={{ marginBottom: '8px' }}>MA/M.Sc</li>
//           </Typography>
//           <Grid item xs={12} container justifyContent='center'>
//             <Button variant="contained" color="primary" onClick={() => handleButtonClick(4)}>
//               Select 4 Exam Set
//             </Button>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Typography variant='h3' align='center' sx={{ marginTop: '20px' }}>
//         {examsSet}
//       </Typography>
//       {examsSet && (
//       <>

//           {componentsMap[examsSet]}
//           <Button variant="contained" color="secondary" onClick={handleResetClick} sx={{ marginTop: '10px' }}>
//             Reset
//           </Button>
//           </>
//       )}

//     </Grid>
//   );
// }

// export default App;


import React, { useState } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import ExamSet3Component from './components/ExamSet3Component';
import ExamSet2Component from './components/ExamSet2Component';
import ExamSet4Component from './components/ExamSet4Component';

const componentsMap = {
  'Select 2 Exam Set': <ExamSet2Component />,
  'Select 3 Exam Set': <ExamSet3Component />,
  'Select 4 Exam Set': <ExamSet4Component />,
};

const App = () => {
  const [examsSet, setExamsSet] = useState('');

  const handleButtonClick = (setNumber) => {
    setExamsSet(`Select ${setNumber} Exam Set`);
  };

  const handleResetClick = () => {
    setExamsSet('');
  };

  return (
    <Box sx={{ maxWidth: 'lg', mx: 'auto', p: 4, boxShadow: 3, borderRadius: 2 }}>
      <Grid container direction="column" alignItems="center">
        <Typography variant='h2' align='center' sx={{ marginBottom: '20px', fontWeight: 'bold', color: 'primary.main' }}>
          PPSC Merit Calculator
        </Typography>
        <Typography variant='h4' align='center' sx={{ marginBottom: '20px', color: 'secondary.main' }}>
          Please Select Your Exams Set
        </Typography>
        <Grid container spacing={2} justifyContent='center' mt='20px'>
          {[2, 3, 4].map((setNumber) => (
            <Grid item xs={12} sm={4} md={3} key={setNumber}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleButtonClick(setNumber)}
                sx={{
                  mb: 2,
                  bgcolor: 'primary.light',
                  '&:hover': { bgcolor: 'primary.dark' },
                  width: '100%',
                }}
              >
                {`Select ${setNumber} Exam Set`}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Typography variant='h3' align='center' sx={{ marginTop: '20px', fontWeight: 'medium', color: 'info.main' }}>
          {examsSet}
        </Typography>
        {examsSet && (
          <Box sx={{ width: '100%', mt: 3 }}>
            {componentsMap[examsSet]}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleResetClick}
              sx={{ marginTop: '10px', bgcolor: 'error.main', '&:hover': { bgcolor: 'error.dark' } }}
            >
              Reset
            </Button>
          </Box>
        )}
      </Grid>
    </Box>
  );
}

export default App;
